import { useState, useEffect } from 'react';
import axios from 'axios';

const useLastFiveSigners = (url, params, shouldFetch) => {
  const [lastFiveSigners, setLastFiveSigners] = useState([]);

  useEffect(() => {
    if(!shouldFetch) return;
    const fetchLastFiveSigners = async (cutoff) => {
      try {
        const res = await axios.get(url, { params: { ...params, cutoff } });
        const filteredSigners = res?.data?.filter(({ country, first_name }) => country?.length === 2 && first_name && country !== 'CN');
        setLastFiveSigners(filteredSigners.slice(0, 5));
      } catch (error) {
        console.error(error);
      }
    };
      fetchLastFiveSigners(5);

    const intervalId = setInterval(() => {
      fetchLastFiveSigners(1);
    }, 90000);
    return () => clearInterval(intervalId);
  }, [url, shouldFetch]);

  return { lastFiveSigners };
};

export default useLastFiveSigners;
