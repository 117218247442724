import React from 'react';
import ReactDOM from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import App from './App';
import GlobalContext from './context/GlobalContext';

import * as Sentry from "@sentry/react";

Sentry.init({
  dsn: "https://f49f4305c63e88325316e2db9830398b@o4508122319552512.ingest.de.sentry.io/4508131362537552",
  integrations: [],
  sampleRate: 0.1,
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <HelmetProvider>
      <GlobalContext>
        <App />
      </GlobalContext>
    </HelmetProvider>
  </React.StrictMode>
);
